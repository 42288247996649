<template>
  <landing-central-card>
    <v-card flat class="signin-card flexcard" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="loading"></v-card-loader>

      <template v-else>
        <v-card-text class="pa-0">
          <v-form
              ref="form"
              v-model="valid"
              autocomplete="on"
              @keydown.enter.native="handleSubmit">

            <v-layout row wrap justify-center gap-xs-3>
              <v-flex xs12 sm11 md10>
                <h1 class="starling-h1">{{ $t('public.signin.title') }}</h1>
              </v-flex>

              <v-flex xs12 sm11 md10>
                <v-layout row :wrap="$vuetify.breakpoint.xsOnly" justify-center gap-xs-2>
                  <v-flex xs12 sm4>
                    <v-btn class="social_login" block outline @click="federatedSignIn('SignInWithApple')">
                      <v-icon>$vuetify.icons.starling_social_apple</v-icon>
                      Apple
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm4>
                    <v-btn class="social_login" block outline @click="federatedSignIn('Google')">
                      <v-icon>$vuetify.icons.starling_social_google</v-icon>
                      Google
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm4 v-if="false">
                    <v-btn class="social_login" block outline @click="federatedSignIn('Facebook')">
                      <v-icon>$vuetify.icons.starling_social_facebook</v-icon>
                      Facebook
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs10 sm8 md7>
                <p class="border-with-text"><span>{{ $t('common.actions.or') }}</span></p>
              </v-flex>

              <v-flex xs12 sm11 md10>
                <v-text-field
                    id="field-username"
                    name="username"
                    autocomplete="username"
                    type="email"
                    v-model="username"
                    :rules="loginRules"
                    :label="$t('public.signin.fields.username')"
                    :readonly="loading"
                    @input="error=null"
                    required>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm11 md10>
                <v-text-field
                    id="field-password"
                    name="password"
                    autocomplete="password"
                    v-model="password"
                    :rules="passwordRules"
                    :label="$t('public.signin.fields.password')"
                    :readonly="loading"
                    :append-icon="!showPassword ? '$vuetify.icons.starling_eye_closed' : '$vuetify.icons.starling_eye_opened'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @input="error=null"
                    required>
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap justify-center align-center pb-2 v-if="error">
              <v-flex xs10 sm8 md6>
                <div class="error--text">{{ error }}</div>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-0 pt-3">
          <v-layout row wrap justify-center align-center gap-xs-3>
            <v-flex xs12 text-center>
              <primary-button
                  id="sign-in"
                  ref="loginBtn"
                  type="submit"
                  large
                  :disabled="!valid || loading"
                  :loading="loading"
                  @click="login">
                {{ $t('public.signin.buttons.signin') }}
              </primary-button>
            </v-flex>

            <v-flex xs12 text-center>
              <router-link
                id="btn-not-registered"
                :to="{name:'sign_up'}"
                           class="body-2 primary--text d-block">
                {{ $t('public.signin.buttons.not_registered') }}
              </router-link>
            </v-flex>

            <v-flex xs12 text-center>
              <router-link
                id="btn-forgotten-password"
                to="/landing/forgotten_password"
                class="body-2 text--disabled d-block">
                {{ $t('public.signin.buttons.forgotten_password') }}
              </router-link>
            </v-flex>

            <v-flex xs12 text-center>
              <div class="caption" v-html="$t('public.signin.buttons.having_questions')"></div>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </template>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import { VALIDATION_REGEX } from '@/scripts/api';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import { Auth } from 'aws-amplify';
import { mapState } from 'vuex';
import store from '@/scripts/store';

export default {
  name: 'sign-in',
  components: {
    PrimaryButton,
    LandingCentralCard,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      valid: false,
      showPassword: false,
      error: null,
      username: this.$route.params.email || '',
      password: '',
      loginRules: [
        v => (v && v.length > 0) || this.$t('common.errors.required'),
        v => VALIDATION_REGEX.EMAIL.test(v) || this.$t('common.errors.email'),
        v => v.length <= 254 || this.$t('common.errors.max254'),
      ],
      passwordRules: [
        v => (v && v.length > 0) || this.$t('common.errors.required'),
        v => v.length >= 8 || this.$t('common.errors.min8'),
      ],
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
    loading: {
      get() {
        return this.$store.state.loadingView;
      },
      set(newValue) {
        this.$store.commit('setLoadingView', newValue);
      },
    },
  },
  methods: {
    back() {
      this.$router.push('/landing/presentation');
    },
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = null;
        this.$store.dispatch('identity/login', {
          username: this.username,
          password: this.password,
        }).then(loginResult => {
          this.$log.debug('Login result', loginResult?.passwordChange, typeof loginResult, JSON.stringify(loginResult, null, 2));
          if (loginResult?.passwordChange) {
            return this.$router.replace({ name: 'new_password' });
          }
          if (!loginResult || !loginResult.userIdentity || !loginResult.userIdentity.location || !loginResult.userIdentity.location['@type']) {
            throw new Error('Cannot determine user');
          }
          if (loginResult.userIdentity.location['@type'] === 'nowhere') {
            return store.dispatch('identity/checkReferral', { email: loginResult.userIdentity.username }).then(refIdentity => {
              if (refIdentity) {
                return this.$router.replace({
                  name: 'referral_register',
                  query: { referral_key: refIdentity.referralKey },
                });
              }
              return this.$router.replace({ name: 'setup_access_code' });
            });
          } else if (loginResult.userIdentity.location['@type'] === 'database') {
            if (!loginResult.redirect) {
              return this.$router.push({ name: 'home' });
            }
          }
          return Promise.resolve();
        }).catch(err => {
          this.$log.error(err);
          if (err && err.code === 'UserNotConfirmedException') {
            return Auth.resendSignUp(this.username, {
              subdomain: this.partner?.subdomain,
              locale: localStorage.getItem('currLang'),
            }).then(res => {
              this.$store.state.auth.email_verification.username = this.username;
              this.$store.state.auth.email_verification.password = this.password;
              this.$store.state.auth.email_verification.uuid = null;
              return this.$router.replace({ name: 'email_verification' });
            });
          } else if (err && err.code === 'PasswordResetRequiredException') {
            this.$store.state.auth.email_verification.username = this.username;
            this.$store.state.auth.email_verification.password = this.password;
            this.$store.state.auth.email_verification.uuid = null;
            return this.$store.dispatch('identity/forgotPassword', {
              username: this.username,
              clientMetadata: {
                subdomain: this.partner?.subdomain,
                locale: this.locale,
              },
            }).then(() => {
              return this.$router.replace({ name: 'reset_password', params: { required: 'true' } });
            });
          }
          this.error = this.$t('public.signin.error.message');
          return Promise.resolve();
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    handleSubmit() {
      if (this.valid) {
        this.login();
      }
    },
    federatedSignIn(provider) {
      this.$router.push({ name: 'federated_start', query: { provider } });
    },
  },
};
</script>

<style scoped>
.signin-card {
  border-radius: 15px;
}

.border-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--StarlingLightGrey);
  color: var(--StarlingLightGrey);
  line-height: 0.1em;
  margin: 10px 0;
}

.border-with-text span {
  background: #fff;
  padding: 0 10px;
}

.social_login {
  min-height: 45px;
  border-radius: 10px;
}
</style>
