var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "signin-card flexcard",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading
            ? _c("v-card-loader")
            : [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        attrs: { autocomplete: "on" },
                        nativeOn: {
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSubmit($event)
                          }
                        },
                        model: {
                          value: _vm.valid,
                          callback: function($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid"
                        }
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              row: "",
                              wrap: "",
                              "justify-center": "",
                              "gap-xs-3": ""
                            }
                          },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("h1", { staticClass: "starling-h1" }, [
                                  _vm._v(_vm._s(_vm.$t("public.signin.title")))
                                ])
                              ]
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      row: "",
                                      wrap: _vm.$vuetify.breakpoint.xsOnly,
                                      "justify-center": "",
                                      "gap-xs-2": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm4: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "social_login",
                                            attrs: { block: "", outline: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.federatedSignIn(
                                                  "SignInWithApple"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "$vuetify.icons.starling_social_apple"
                                              )
                                            ]),
                                            _vm._v(
                                              "\n                    Apple\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm4: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "social_login",
                                            attrs: { block: "", outline: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.federatedSignIn(
                                                  "Google"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                "$vuetify.icons.starling_social_google"
                                              )
                                            ]),
                                            _vm._v(
                                              "\n                    Google\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    false
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", sm4: "" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "social_login",
                                                attrs: {
                                                  block: "",
                                                  outline: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.federatedSignIn(
                                                      "Facebook"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "$vuetify.icons.starling_social_facebook"
                                                  )
                                                ]),
                                                _vm._v(
                                                  "\n                    Facebook\n                  "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs10: "", sm8: "", md7: "" } },
                              [
                                _c("p", { staticClass: "border-with-text" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("common.actions.or")))
                                  ])
                                ])
                              ]
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    id: "field-username",
                                    name: "username",
                                    autocomplete: "username",
                                    type: "email",
                                    rules: _vm.loginRules,
                                    label: _vm.$t(
                                      "public.signin.fields.username"
                                    ),
                                    readonly: _vm.loading,
                                    required: ""
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.error = null
                                    }
                                  },
                                  model: {
                                    value: _vm.username,
                                    callback: function($$v) {
                                      _vm.username = $$v
                                    },
                                    expression: "username"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm11: "", md10: "" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    id: "field-password",
                                    name: "password",
                                    autocomplete: "password",
                                    rules: _vm.passwordRules,
                                    label: _vm.$t(
                                      "public.signin.fields.password"
                                    ),
                                    readonly: _vm.loading,
                                    "append-icon": !_vm.showPassword
                                      ? "$vuetify.icons.starling_eye_closed"
                                      : "$vuetify.icons.starling_eye_opened",
                                    type: _vm.showPassword
                                      ? "text"
                                      : "password",
                                    required: ""
                                  },
                                  on: {
                                    "click:append": function($event) {
                                      _vm.showPassword = !_vm.showPassword
                                    },
                                    input: function($event) {
                                      _vm.error = null
                                    }
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.error
                          ? _c(
                              "v-layout",
                              {
                                attrs: {
                                  row: "",
                                  wrap: "",
                                  "justify-center": "",
                                  "align-center": "",
                                  "pb-2": ""
                                }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs10: "", sm8: "", md6: "" } },
                                  [
                                    _c("div", { staticClass: "error--text" }, [
                                      _vm._v(_vm._s(_vm.error))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { staticClass: "pa-0 pt-3" },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": "",
                          "gap-xs-3": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-center": "" } },
                          [
                            _c(
                              "primary-button",
                              {
                                ref: "loginBtn",
                                attrs: {
                                  id: "sign-in",
                                  type: "submit",
                                  large: "",
                                  disabled: !_vm.valid || _vm.loading,
                                  loading: _vm.loading
                                },
                                on: { click: _vm.login }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("public.signin.buttons.signin")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-center": "" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "body-2 primary--text d-block",
                                attrs: {
                                  id: "btn-not-registered",
                                  to: { name: "sign_up" }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signin.buttons.not_registered"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-center": "" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "body-2 text--disabled d-block",
                                attrs: {
                                  id: "btn-forgotten-password",
                                  to: "/landing/forgotten_password"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "public.signin.buttons.forgotten_password"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-center": "" } },
                          [
                            _c("div", {
                              staticClass: "caption",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t(
                                    "public.signin.buttons.having_questions"
                                  )
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }